import PropTypes from 'prop-types';
// @mui
import { Box, Card, Typography } from '@mui/material';
// utils
import { fCurrency, fNumber } from '../../../utils/formatNumber';

HeaderCard.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function HeaderCard({ notAmount, title, total, sx, ...other  }) {
  return (
    <Card
      sx={{ display: 'flex', alignItems: 'center', p: 3, backgroundColor: '#ffbe0069', height: '100%', ...sx }}
      {...other}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="h3">{notAmount ? total : fCurrency(total)}</Typography>
      </Box>
    </Card>
  );
}
