import { Box, Button, Grid, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from '../../../../components/Iconify';
import SelectInput from '../../../../components/hook-form/SelectInput';
import CustomDateRangePicker from '../../../../components/input/CustomDatePicker';
import { fCustomDate } from '../../../../utils/formatTime';
import { KYC_DOC_TYPE, KYC_STATUSES } from '../../../../utils/apiUrl';
import { adminApi } from '../../../../utils/axios';

const RetailKycFilter = ({ filterParameters, onFilterChange }) => {
  const currentDate = new Date();

  // Getting Date which is 30 Days back from current date
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  const [showFilter, setShowFilter] = useState(false);
  const [transactionStatusData, setTransactionStatusData] = useState([]);
  const [docTypeData, setDocTypeData] = useState([]);
  const [filterData, setFilterData] = useState(filterParameters);

  useEffect(() => {
    const timer = setTimeout(() => onFilterChange(filterData), 800);

    return () => clearTimeout(timer);
  }, [filterData]);

  useEffect(() => {
    getTransactionStatusData();
    getDocTypeData();
  }, []);

  // Getting Status data
  const getTransactionStatusData = async () => {
    try {
      const response = await adminApi.get(KYC_STATUSES);
      setTransactionStatusData(response?.data.data.map((data) => ({ label: data.name, value: data.value })));
    } catch (error) {
      console.log(error);
    }
  };

  // Getting Doc Type data
  const getDocTypeData = async () => {
    try {
      const response = await adminApi.get(KYC_DOC_TYPE);
      // console.log('doc type', response?.data);
      setDocTypeData(response?.data?.data?.doc_types.map((data) => ({ label: data.name, value: data.value })));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ margin: '20px 24px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={2}>
          <Button
            sx={{ paddingBlock: '15px', width: '100%' }}
            variant="contained"
            endIcon={<Iconify icon={'material-symbols:filter-list-rounded'} />}
            onClick={() => setShowFilter((prev) => !prev)}
          >
            Filters
          </Button>
        </Grid>
      </Grid>
      {showFilter && (
        <>
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={12} md={6} lg={6}>
              <CustomDateRangePicker
                label1="Start Date"
                label2="End Date"
                name1="from_date"
                name2="to_date"
                onChange={(e, name) => setFilterData((prev) => ({ ...prev, [name]: e === null ? '' : fCustomDate(e) }))}
                val1={filterData?.from_date}
                val2={filterData?.to_date}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ textAlign: 'right' }}>
              <Button
                sx={{ paddingBlock: '15px' }}
                variant="contained"
                onClick={() =>
                  setFilterData({
                    search: '',
                    status: '',
                    document_type: '',
                    from_date: fCustomDate(new Date(thirtyDaysAgo)),
                    to_date: fCustomDate(new Date(currentDate)),
                  })
                }
              >
                Reset Filters
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                onChange={(event) => setFilterData((prev) => ({ ...prev, search: event.target.value }))}
                placeholder="Search by ID, Name, Phone and Email"
                value={filterData.search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <SelectInput
                label="Document Type"
                optionsRole={docTypeData}
                onChange={(event) => setFilterData((prev) => ({ ...prev, document_type: event.target.value }))}
                value={filterData.document_type}
                name="document_type"
                isClear={() => setFilterData((prev) => ({ ...prev, document_type: '' }))}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <SelectInput
                label="Status"
                optionsRole={transactionStatusData}
                onChange={(event) => setFilterData((prev) => ({ ...prev, status: event.target.value }))}
                value={filterData.status}
                name="status"
                isClear={() => setFilterData((prev) => ({ ...prev, status: '' }))}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default RetailKycFilter;
