import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import WithdrawlReportData from './withdrawlReportData';

const WithdrawlReport = () => {

  return (
    <Page title="Withdrawl Report">
      <HeaderBreadcrumbs
        heading="Withdrawl Report"
        links={[{ name: 'Withdrawl' }]}
      />
      <Box sx={{ width: '100%' }}>
        <WithdrawlReportData />
      </Box>
    </Page>
  );
};

export default WithdrawlReport;
