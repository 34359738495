import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import HeaderCard from '../../../../components/cards/headerCard/headerCard';

const MerchantKybHeaders = ({ totalData }) => {
  return (
    <Box sx={{ paddingInline: '24px', paddingBottom: '24px' }}>
      <Grid container spacing={3} sx={{ marginTop: 0 }}>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard title="Pending Count" notAmount total={totalData.pending} sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard title="In Process Count" notAmount total={totalData.in_process} sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard title="Approved Count" notAmount total={totalData.approved} sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard title="Rejected Count" notAmount total={totalData.rejected} sx={{ width: '100%' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MerchantKybHeaders;
