export const ADMIN = '/admin';
export const VERSION_V1 = '/v1';
export const USER = `${VERSION_V1}/user${ADMIN}`;
export const MERCHANT_USERS = `${USER}/merchantuser/`;
export const MERCHANT_BUSINESS_LIST = `${VERSION_V1}/merchant/admin/list`;
export const RETAIL_USERS = `${USER}/retailuser/`;
export const MERCHANTS = `${VERSION_V1}/merchant/admin/`;

export const COUNTRIES = `${VERSION_V1}/countries/`;
export const LANGUAGES = `${VERSION_V1}/language/`;
export const CURRENCIES = `${VERSION_V1}/currency/`;
export const USER_KYC = `${VERSION_V1}/user/kyc/admin/`;
export const USER_KYC_HIGHLIGHTS = `${VERSION_V1}/user/kyc/admin/highlights/`;
export const KYC_DOC_TYPE = `${VERSION_V1}/user/kyc/doc_type/`;
export const MERCHANT_KYB = `${VERSION_V1}/merchant/admin/kyb/`;
export const MERCHANT_KYB_HIGHLIGHTS = `${VERSION_V1}/merchant/admin/kyb/highlights`;
export const PASSWORD_HISTORY = `${VERSION_V1}/user/admin/password_history/`;
export const UPDATE_PASSWORD = `${VERSION_V1}/user/admin/update_password/`;
export const UPDATE_PASSWORD_OTP_VERIFY = `${VERSION_V1}/user/admin/password_otp_verify/`;
export const UPDATE_PASSWORD_RESEND_OTP = `${VERSION_V1}/user/admin/resend_otp/`;
export const KYC_STATUSES = `${VERSION_V1}/user/kyc/admin/statuses/`;
export const AUDIT_LOG = `${VERSION_V1}/auditlog/`;
export const RETAIL_USER_OFFER = `${VERSION_V1}/transaction/admin/offer_transaction_summary/`;

// Wallet
export const WALLETS = `${VERSION_V1}/wallet/admin/`;
export const WALLET_ACCOUNTS = `${VERSION_V1}/wallet/admin/wallet_account/`;

// Transactions
export const TRANSACTIONS = `${VERSION_V1}/transaction/admin/`;
export const BITGO_TRANSACTIONS = `${VERSION_V1}/transaction/admin/bitgo_transaction/`;

// Support
export const SUPPORT_AGENTS = `${VERSION_V1}/support/admin/agent/`;
export const SUPPORT = `${VERSION_V1}/support/admin/user_support/ticket/`;
export const MERCHANT_SUPPORT = `${VERSION_V1}/support/admin/merchant_support/ticket/`;
export const MERCHANT_PRIORITY = `${VERSION_V1}/support/admin/merchant_support/priority/`;
export const SUPPORT_CATEGORY = `${VERSION_V1}/support/admin/merchant_support/category/`;
export const USER_TICKETS = `${VERSION_V1}/support/admin/user_support/ticket/`;
export const MERCHANT_TICKETS = `${VERSION_V1}/support/admin/merchant_support/ticket/`;
export const MERCHANT_TICKET_REPLY = `${VERSION_V1}/support/admin/merchant_support/reply/`;
export const USER_TICKET_REPLY = `${VERSION_V1}/support/admin/user_support/reply/`;
export const MERCHANT_FAQ = `${VERSION_V1}/support/admin/merchant_support/faq/`;

// Coins
export const COINS = `${VERSION_V1}/admin/coin/`;

// Configurtions
export const ADMIN_OFFERS = `${VERSION_V1}/offer/admin/`;
export const ADMIN_EMAILS = `${VERSION_V1}/admin/email/`;
export const DEROGATORY = `${VERSION_V1}/admin/derogatory/`;
export const APP_CONFIGURATIONS = `${VERSION_V1}/admin/configure/`;
export const APP_VERSIONS = `${VERSION_V1}/admin/version/`;
export const XRPL_STATUS = `${VERSION_V1}/admin/xrpl_status/`;
export const ADMIN_LANGUAGES = `${VERSION_V1}/admin/language/`;
export const ADMIN_COUNTRY = `${VERSION_V1}/admin/country/`;
export const ADMIN_CURRENCY = `${VERSION_V1}/admin/currency/`;
export const ADMIN_EXCHANGE_RATE = `${VERSION_V1}/admin/exchange_rate/`;
export const ADMIN_PROVINCE = `${VERSION_V1}/admin/province/`;
export const ADMIN_ACCUANT_ERROR = `${VERSION_V1}/admin/acuant_error/`;
export const ADMIN_COUNTRY_SUPPORTED_COIN = `${VERSION_V1}/admin/supported_coin/`;
export const ADMIN_COMMISSION = `${VERSION_V1}/admin/commission/`;
export const ADMIN_STAFF_USER = `${VERSION_V1}/user/admin/staffuser/`;
export const ADMIN_STAFF_SEND_OTP = `${VERSION_V1}/user/send_otp/`;
export const ADMIN_STAFF_VERIFY_OTP = `${VERSION_V1}/user/verify_otp/`;
export const ADMIN_STAFF_RESEND_PASSWORD_LINK = `${VERSION_V1}/user/admin/staffuser/resend_mail`;
export const ADMIN_TRANSACTION_FEE_TYPE = `${VERSION_V1}/transaction/admin/transaction_fee_types/`;
export const ADMIN_USER_TYPE = `${VERSION_V1}/transaction/admin/fee_user_types/`;
export const ADMIN_FEE_SLAB_LIST = `${VERSION_V1}/transaction/admin/fees/`;
export const ADMIN_FEE_SLAB_HISTORY = `${VERSION_V1}/transaction/admin/fees/history/`;

//
export const TRANSACTIONS_STATUS = `${VERSION_V1}/transaction/admin/status/`;
export const TRANSACTIONS_TYPE = `${VERSION_V1}/transaction/type/`;
export const STORE_COUNTER = `${VERSION_V1}/merchant/store/counter/`;
export const USERS = `${VERSION_V1}/user/`;
export const CENTRAL_WALLET = `${VERSION_V1}/wallet/admin/central_wallet/`;
export const FUNDS_QUEUE_TRANSACTIONS = `${VERSION_V1}/fund/admin/`;
export const WITHDRAW_QUEUE_TRANSACTIONS = `${VERSION_V1}/withdraw/admin/`;

// Merchant
export const BUSINESS_CATEGORY = `${VERSION_V1}/merchant/admin/category/`;
export const CATEGORY = `${VERSION_V1}/merchant/admin/category/`;
export const BUSINESS_TYPES = `${VERSION_V1}/merchant/admin/type/`;
export const MERCHANT_CONFIG = `${VERSION_V1}/merchant/admin/config/`;
export const MERCHANT_LIST = `${VERSION_V1}/merchant/admin/`;
export const MERCHANT_ROLES = `${VERSION_V1}/merchant/admin/role/`;
export const KYB_DOCUMENTS = `${VERSION_V1}/merchant/admin/kyb/document_type/`;
export const MERCHANT_STATUS = `${VERSION_V1}/merchant/admin/statuses/`;
export const MERCHANT_STORES = `${VERSION_V1}/merchant/admin/store/`;
export const MERCHANT_USER_ROLES = `${VERSION_V1}/user/admin/merchantuser/roles/`;
export const MERCHANT_STORE_COUNTERS = `${VERSION_V1}/merchant/admin/store/pos/`;
export const MERCHANT_USER_CREATE = `${VERSION_V1}/user/admin/merchantuser/`;
export const MERCHANT_STORE = `${VERSION_V1}/merchant/admin/store/`;
export const MERCHANT_BUSINESS_CATEGORY = `${VERSION_V1}/merchant/admin/category/`;
export const MERCHANT_BUSINESS_TYPE = `${VERSION_V1}/merchant/admin/business_type/`;
export const MERCHANT_SHAREHOLDER = `${VERSION_V1}/merchant/admin/shareholder/`;
export const MERCHANT_SHAREHOLDER_KYC = `${VERSION_V1}/merchant/admin/shareholder_kyc/`;
export const MERCHANT_SYSTEM_USER = `${VERSION_V1}/merchant/admin/merchant_system_user/`;

// Audit Trail
export const AUDIT_TRAIL = `${VERSION_V1}/audit/admin/`;
export const AUDIT_TRAIL_CATEGORY = `${VERSION_V1}/audit/admin/category`;

// NOTIFICATION
export const ADMIN_NOTIFICATION = `${VERSION_V1}/notification/admin/`;

// REPORTING
export const ADMIN_REVENUE_REPORTING = `${VERSION_V1}/reports/revenue_report/`;
export const ADMIN_REVENUE_REPORTING_HIGHLIGHT = `${VERSION_V1}/reports/revenue_report_highlight/`;
export const ADMIN_REVENUE_COMPONENT_BREAK_REPORTING = `${VERSION_V1}/reports/revenue_component_report/`;
export const ADMIN_REVENUE_COMPONENT_BREAK_REPORTING_HIGHLIGHT = `${VERSION_V1}/reports/revenue_component_report_highlight/`;
export const ADMIN_TRANSACTION_VOLUME = `${VERSION_V1}/reports/transaction_volume/`;
export const ADMIN_TRANSACTION_VOLUME_HIGHLIGHT = `${VERSION_V1}/reports/transaction_volume_highlight/`;
export const ADMIN_DEPOSIT_TYPES = `${VERSION_V1}/reports/deposit_types/`;
export const ADMIN_DEPOSIT_TRANSACTION_VOLUME = `${VERSION_V1}/reports/deposit_transaction_volume/`;
export const ADMIN_DEPOSIT_TRANSACTION_VOLUME_HIGHLIGHT = `${VERSION_V1}/reports/deposit_transaction_volume_highlight/`;
export const ADMIN_WITHDRAW_TRANSACTION_VOLUME = `${VERSION_V1}/reports/withdraw_transaction_volume/`;
export const ADMIN_WITHDRAW_TRANSACTION_VOLUME_HIGHLIGHT = `${VERSION_V1}/reports/withdraw_transaction_volume_highlight/`;
export const ADMIN_WITHDRAW_TYPES = `${VERSION_V1}/reports/withdraw_types/`;
export const ADMIN_BILL_PAYMENTS = `${VERSION_V1}/reports/bill_payment_transaction_volume/`;
export const ADMIN_BILL_PAYMENTS_PAYEE = `${VERSION_V1}/billpayment/bill_payment_accounts/`;
export const ADMIN_BILL_PAYMENTS_HIGHLIGHT = `${VERSION_V1}/reports/bill_payment_transaction_volume_highlight/`;
export const ADMIN_BALANCE_DIFFRENCE_REPORT = `${VERSION_V1}/reports/balance_difference_report/`;
export const ADMIN_RETAIL_REPORT = `${VERSION_V1}/reports/admin/retail_master_transaction/`;
export const ADMIN_P2P_TRANSACTION = `${VERSION_V1}/reports/admin/p2p_transaction/`;
export const ADMIN_P2B_TRANSACTION = `${VERSION_V1}/reports/admin/p2b_transaction/`;
export const ADMIN_BILL_PAYMENTS_REPORT = `${VERSION_V1}/reports/admin/bill_payment/`;
export const ADMIN_DEPOSIT_REPORT = `${VERSION_V1}/reports/admin/deposit/`;
export const ADMIN_EFT_ACCOUNTS = `${VERSION_V1}/fund/admin/eft_customer_account/`;
export const ADMIN_TRANSACTION_TYPES = `${VERSION_V1}/transaction/admin/types/`;
export const ADMIN_PAYMENT_MODE = `${VERSION_V1}/transaction/admin/payment_modes/`;
export const ADMIN_TRANSACTION_STATUS = `${VERSION_V1}/transaction/admin/status/`;
export const ADMIN_FUND_STATUS = `${VERSION_V1}/fund/admin/statuses/`;
export const ADMIN_TRANSACTION_USER_TYPE = `${VERSION_V1}/admin/transaction_fee_user_types/`;

export const REFRESH_TOKEN_API = `${VERSION_V1}/refresh/`;
