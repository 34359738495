import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import HeaderCard from '../../../../components/cards/headerCard/headerCard';

const P2pTransactionHeaders = ({ netEarning, totalData, isProfitLoss }) => {
  return (
    <Box sx={{ paddingInline: '24px', paddingBottom: '24px' }}>
      <Grid container spacing={3} sx={{marginTop: 0}}>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard title="Total Number of Transactions" notAmount total={totalData.total_transactions || 0} sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <HeaderCard title="Transaction Value" total={totalData.transaction_value || 0} sx={{ width: '100%' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default P2pTransactionHeaders;
