import * as React from 'react';
import PropTypes from 'prop-types';
import { Stack, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

CustomDateRangePicker.propTypes = {
  val1: PropTypes.any,
  val2: PropTypes.any,
  onChange: PropTypes.any,
  label1: PropTypes.any,
  label2: PropTypes.any,
  name1: PropTypes.any,
  name2: PropTypes.any,
};

export default function CustomDateRangePicker({ val1, val2, onChange, label1, label2, name1, name2 }) {
  return (
    <>
      <Stack sx={{ flexDirection: 'row', gap: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label1}
            value={val1 === null || val1 === '' ? null : dayjs(val1)}
            // {...field}
            format="DD/MM/YYYY"
            onChange={(e) => onChange(e, name1)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Typography marginTop="15px">-</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label2}
            value={val2 === null || val2 === '' ? null : dayjs(val2)}
            format="DD/MM/YYYY"
            minDate={dayjs(val1)}
            disabled={val1 === null || val1 === ''}
            onChange={(e) => onChange(e, name2)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Stack>
    </>
  );
}
