/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { PATH_AFTER_LOGIN } from '../config';
import Image from './Image';
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  isLogin: PropTypes.any,
};

export default function Logo({ disabledLink = false, sx, isLogin }) {
  const theme = useTheme();
  const { themeMode } = useSettings();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  const logo = (
    <Image
      alt="SpendTheBits"
      sx={{
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
      src={themeMode === 'light' ? '/assets/logo_light.png' : '/assets/logo.png'}
    />
  );

  if (disabledLink) {
    return (
      <Image
        alt="SpendTheBits"
        sx={{
          width: '40px',
          height: '70px',
        }}
        src={'/assets/logo_icon.png'}
      />
    );
  }
  if (isLogin) {
    return (
      <Image
        alt="SpendTheBits"
        sx={{
          width: 'calc(30% - 10em)',
          height: 'calc(30% - 10em)',
        }}
        src={themeMode === 'light' ? '/assets/logo_light.png' : '/assets/logo.png'}
      />
    );
  }

  return <RouterLink to={PATH_AFTER_LOGIN}>{logo}</RouterLink>;
}
