import { useEffect } from 'react';
// routes
import { useLocation } from 'react-router-dom';
import Router from './routes';
// redux
import { useDispatch } from './redux/store';
import { getCountries, getLanguages, getCurrencies, getKycStatuses } from './redux/slices/general';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import useAuth from './hooks/useAuth';

// ----------------------------------------------------------------------

export default function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCountries());
      dispatch(getLanguages());
      dispatch(getCurrencies());
      dispatch(getKycStatuses());
    }
  }, [isAuthenticated, dispatch]);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
