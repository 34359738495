import PropTypes from 'prop-types';
// form
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// ----------------------------------------------------------------------

RHFDateTimePicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFDateTimePicker({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            {...field}
            onChange={(value) => field.onChange(value)}
            renderInput={(props) => <TextField {...props} />}
            error={!!error}
            helperText={error?.message}
            disablePast
            views={['year', 'month', 'day', 'hours', 'minutes']}
            {...other}
          />
        </LocalizationProvider>
      )}
    />
  );
}
