import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

CustomSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  isClear: PropTypes.any,
  isDisabled: PropTypes.bool,
};

export default function CustomSelect({ name, children, isClear, isDisabled, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          {...other}
          {...field}
          select
          fullWidth
          // size={size}
          error={!!error}
          helperText={error?.message}
          name={name}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          InputProps={{
            startAdornment: field?.value && (
              <InputAdornment position="start" onClick={() => isClear(isDisabled ? '' : name)}>
                <IconButton>
                  <Iconify icon={'majesticons:close-line'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {children}
        </TextField>
      )}
    />
  );
}
