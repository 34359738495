/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
// form
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, CardHeader, Container, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RHFTextField, FormProvider } from '../../../../../components/hook-form';
import CustomSelect from '../../../../../components/hook-form/CustomSelectInput';
import { adminApi } from '../../../../../utils/axios';
import {
  ADMIN_COUNTRY,
  ADMIN_CURRENCY,
  MERCHANT_BUSINESS_CATEGORY,
  MERCHANT_BUSINESS_TYPE,
  MERCHANT_LIST,
  MERCHANT_ROLES,
  MERCHANT_STATUS,
  MERCHANT_STORE,
  MERCHANT_STORE_COUNTERS,
  MERCHANT_SYSTEM_USER,
  MERCHANT_USER_ROLES,
} from '../../../../../utils/apiUrl';
// import MerchantKycKybStatusForm from './merchantKycKybStatusForm';
import RHFPhoneInput from '../../../../../components/hook-form/RHFPhoneInput';
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import useSettings from '../../../../../hooks/useSettings';
import { PATH_ACCOUNT, PATH_MERCHANTS } from '../../../../../routes/paths';
import Iconify from '../../../../../components/Iconify';

// MerchantBusinessInfo.propTypes = {
//   merchantData: PropTypes.any,
//   kycStatuses: PropTypes.any,
// };

export default function MerchantUserCreateSystemUser() {
  const [isLoading, setIsLoading] = useState(false);
  const [currencyTypeData, setCurrencyTypeData] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [counterList, setCounterList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const userState = location.state;

  console.log('merchant system user', userState);

  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();

  const merchantUserCreateStoreSchema = Yup.object().shape({
    first_name: Yup.string().required('This is required'),
    last_name: Yup.string().required('This is required'),
    email: Yup.string().email('Invalid Email').required('This is required'),
    phone_number: Yup.string().required('This is required'),
    user_role: Yup.string().required('This is required'),
  });

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = () => {
    // Getting store list
    adminApi
      .get(`${MERCHANT_STORE}`, {
        params: {
          merchant: userState?.merchantId,
          limit: 100,
        },
      })
      .then(({ data }) => {
        setStoreList(data?.results);
      })
      .catch((error) => {
        enqueueSnackbar(error?.message, { variant: 'error' });
      });

    // Getting User role list
    adminApi
      .get(MERCHANT_USER_ROLES, {
        params: {
          limit: 100,
        },
      })
      .then(({ data }) => {
        console.log('store list', data?.results);
        setUserRoleList(data?.data);
      })
      .catch((error) => {
        // console.log('Error: ', error.message);
        enqueueSnackbar(error?.message, { variant: 'error' });
      });
  };

  const defaultValues = useMemo(
    () => ({
      first_name: userState?.currentUser?.first_name || '',
      last_name: userState?.currentUser?.last_name || '',
      email: userState?.currentUser?.email || '',
      phone_number: userState?.currentUser?.phone_number || '',
      user_role: userState?.currentUser?.user_role || '',
      store: userState?.currentUser?.store?.id || '',
      counter: userState?.currentUser?.counter?.id || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(merchantUserCreateStoreSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleClear = (name) => {
    setValue(name, '');
  };

  const values = watch();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      if (userState?.isEdit) {
        const updatedDataObj = {};
        const keys = Object.keys(defaultValues);
        keys.forEach((key) => {
          if (defaultValues[key] !== data[key]) {
            updatedDataObj[key] = data[key];
          }
        });
        adminApi
          .patch(`${MERCHANT_SYSTEM_USER}${userState?.currentUser?.id}/`, updatedDataObj)
          .then(() => {
            setIsLoading(false);
            enqueueSnackbar(!userState?.isEdit ? 'Create success!' : 'Update success!');
            navigate(PATH_MERCHANTS.merchants.profile(id, 'employees'));
          })
          .catch((error) => {
            if (typeof error?.response?.data === 'object' && error?.response?.status === 400) {
              setIsLoading(false);
              enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
            } else {
              setIsLoading(false);
              enqueueSnackbar('Something went wrong', { variant: 'error' });
            }
          });
      } else {
        adminApi
          .post(`${MERCHANT_SYSTEM_USER}?merchant=${userState?.merchantId}`, data)
          .then(() => {
            reset();
            setIsLoading(false);
            enqueueSnackbar(!userState?.isEdit ? 'Create success!' : 'Update success!');
            navigate(PATH_MERCHANTS.merchants.profile(id, 'employees'));
          })
          .catch((error) => {
            if (typeof error?.response?.data === 'object' && error?.response?.status === 400) {
              setIsLoading(false);
              enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
            } else {
              setIsLoading(false);
              enqueueSnackbar('Something went wrong', { variant: 'error' });
            }
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (values.store && values.store !== '') {
      // Getting counter list
      adminApi
        .get(MERCHANT_STORE_COUNTERS, {
          params: {
            store: values.store,
            limit: 100,
          },
        })
        .then(({ data }) => {
          // console.log('counter list', data?.results);
          setCounterList(data?.results);
        })
        .catch((error) => {
          enqueueSnackbar(error?.message, { variant: 'error' });
        });
    }
  }, [values.store]);

  return (
    <Page title={userState?.isEdit ? 'Edit System User' : 'Add System User'}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '10%' }}
          onClick={() => navigate(-1)}
        >
          <Iconify icon={'ion:arrow-back'} width={30} height={20} />
          <Typography>Back</Typography>
        </Box>
        <HeaderBreadcrumbs
          heading={userState?.isEdit ? 'Edit System User' : 'Add System User'}
          links={[{ name: 'System User' }, { name: userState?.isEdit ? 'Edit' : 'Add' }]}
        />
        <Card sx={{ maxWidth: '80%', margin: '0 auto' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                p: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="first_name" label="First Name" />
              <RHFTextField name="last_name" label="Last Name" />
              <RHFTextField name="email" label="Email" />
              <RHFPhoneInput name="phone_number" label="Phone Number" />
              <CustomSelect
                name="user_role"
                label="User Role"
                placeholder="User Role"
                isClear={handleClear}
                isDisabled={!(userRoleList && userRoleList.length > 0)}
              >
                {userRoleList?.map((option) => (
                  <MenuItem
                    selected
                    key={option?.name}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option?.name}
                  </MenuItem>
                ))}
              </CustomSelect>
              {(values.user_role === 'manager' || values.user_role === 'cashier') && (
                <CustomSelect
                  name="store"
                  label="Store"
                  placeholder="Store"
                  isClear={handleClear}
                  isDisabled={!(storeList && storeList.length > 0)}
                >
                  {storeList?.map((option) => (
                    <MenuItem
                      selected
                      key={option?.name}
                      value={option?.id}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                      }}
                    >
                      {option?.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
              {values.user_role === 'cashier' && (
                <CustomSelect
                  name="counter"
                  label="Counter"
                  placeholder="Counter"
                  isClear={handleClear}
                  isDisabled={!(counterList && counterList.length > 0)}
                >
                  {counterList?.map((option) => (
                    <MenuItem
                      // selected={value === option.value}
                      selected
                      key={option?.name}
                      value={option?.id}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                      }}
                    >
                      {option?.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            </Box>
            <Stack spacing={3} alignItems="flex-end" sx={{ p: 3, marginLeft: 'auto' }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <LoadingButton type="submit" variant="contained" loading={isLoading}>
                  {userState?.isEdit ? 'Update User' : 'Add User'}
                </LoadingButton>
              </Box>
            </Stack>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}
