import { useNavigate } from 'react-router';
import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { fCurrency } from '../../../../utils/formatNumber';
import { fDate } from '../../../../utils/formatTime';
import Label from '../../../../components/Label';
import { PATH_ACCOUNT } from '../../../../routes/paths';

const BillPaymentsTableRow = ({ row }) => {
  const {
    id,
    reference_number,
    created_date,
    sender,
    receiver,
    send_amount,
    status,
    receive_amount,
  } = row;

  const navigate = useNavigate();

  return (
    <TableRow key={id}>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        {fDate(created_date)}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        {reference_number}
      </TableCell>
      <TableCell
        align="left"
        sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
        onClick={() => navigate(PATH_ACCOUNT.retailUsers.profile(sender?.wallet_account_id, 'transactions'))}
      >
        {sender?.name}
      </TableCell>
      <TableCell
        align="left"
        sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
        onClick={() => navigate(PATH_ACCOUNT.merchantUsers.profile(receiver?.wallet_account_id, 'transactions'))}
      >
        {receiver?.name}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {fCurrency(send_amount)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {fCurrency(receive_amount)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        <Label color={(status === 'processed' && 'success') || 'error'} sx={{ textTransform: 'capitalize' }}>
          {status}
        </Label>
      </TableCell>
    </TableRow>
  );
};

export default BillPaymentsTableRow;
