import PropTypes from 'prop-types';
import { TextField, Autocomplete, Chip } from '@mui/material';
import { Controller } from 'react-hook-form';

MultiSelectInput.propTypes = {
  onChange: PropTypes.func,
  optionsRole: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isClear: PropTypes.func,
  disabled: PropTypes.bool,
  ref: PropTypes.bool,
  register: PropTypes.any,
  control: PropTypes.any,
  children: PropTypes.any,
  multiline: PropTypes.any,
  rows: PropTypes.any,
  type: PropTypes.any,
  options: PropTypes.any,
};

export default function MultiSelectInput({ onChange, label, name, value, control, options, disabled, ...other }) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={value || []}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            multiple
            freeSolo
            disabled={disabled}
            onChange={(event, value) => field.onChange(value)}
            options={options}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip {...getTagProps({ index })} key={option.value} size="small" label={option.label} />
              ))
            }
            renderInput={(params) => (
              <TextField {...other} label={label} {...params} error={!!error} helperText={error?.message} />
            )}
          />
        )}
      />
    </>
  );
}
